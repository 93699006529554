.centerbox {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: rgb(0 0 0 / 72%);
  position: fixed;
  z-index: 999999;
  top: 0;
}


html[data-theme='light'] .centerbox {



}
  
.centerbox img{
  max-width:150px; 
}
.rotate {
  border: 4px solid #FFC91E;
  border-radius: 50%;
  width: 70px;
  /* animation: rotation 2s infinite linear; */
}
.rotates {
  border: 4px solid #FFC91E;
  border-radius: 50%;
  width: 70px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}



.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: '';
  width: 48px;
  height: 5px;
  background: #FFC91E4f;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader:after {
  content: '';
  width: 100%;
  height: 100%;
  background: #FFC91E;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {

  0%,
    100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}



.loader2 {
	background-color: #1111114d;
	overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: start;
  align-content: center; 
  justify-content: flex-start;  
	z-index: 100000;
}

.loader__element {
	height:  4.5px;
	width: 100%;
	background: #0000;

}

.loader__element:before {
  content: '';
  display: block;
  background-color: #FFC91E;
  height: 4.5px;
  width: 0;
  animation: getWidth 2s ease-in infinite;
}

@keyframes getWidth {
	100% { width: 100%; }
}
